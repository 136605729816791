/* eslint-disable max-lines */
import {
  AbstractControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { isValid, startOfDay } from 'date-fns';
import { differenceInDays } from 'date-fns/esm';

import { format, parseDate } from '../shared/date.helper';

export class ValidationService {
  public static messages = {
    required: '',
    invalidDecimal: _('Is invalid decimal'),
    invalidNumber: _('Is invalid number'),
    invalidCreditCard: _('Is invalid credit card number'),
    email: _('Invalid email address'),
    invalidPassword: _(
      'Your password must be at least 10 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters.',
    ),
    invalidPhone: _('Invalid phone number.'),
    invalidPhoneNumber: _('Telephone number is invalid'),
    minlength: _('Minimum length {{requiredLength}}'),
    maxlength: _('Maximum length {{requiredLength}}'),
    minItems: _('Select at least {{minLength}}'),
    termsAgreement: _('Must be checked'),
    invalidTime: _('This is not a valid time'),
    invalidDate: _('This is not a valid date'),
    max: _('The entered value cannot be higher than {{max}}'),
    min: _('The entered value cannot be lower than {{min}}'),
    maxDate: _('The date can not be after {{maxDate}}'),
    minDate: _('The date can not be before {{minDate}}'),
    period: _('The date can not be before {{minDate}}'),
    recurrence: _('Select at least one day'),
    moreThanPeriod: _('The entered period may not exceed {{ period }} days'),
    selectAtLeastOneCheckbox: _('Select at least one checkbox'),
    negativeNumber: _('The given value cannot be negative'),
    absenceConflict: _('There is a conflict with an existing absence in this period.'),
    invalidEuurUrl: _('The URL should not contain http:// or https://.'),
    containSlashes: _("May not contain '/'."),
    dateBeforeContract: _('Cannot be before employee start date'),
    containsUrl: _('May not contain urls'),
    invalidHexColor: _('Not a valid hex color'),
    invalidVerificationCode: _('Code must be 6 digits'),
    requiredOneOption: _('Please select one option'),
    requiredOneOrMoreOptions: _('Please select one or more options'),
    hasNoContract: _('No contract available for the given period'),
  };

  public static requiredOneOption(control: AbstractControl): ValidationErrors | null {
    return Validators.required(control) ? { requiredOneOption: true } : null;
  }

  public static requiredOneOrMoreOptions(control: AbstractControl): ValidationErrors | null {
    return Validators.required(control) ? { requiredOneOrMoreOptions: true } : null;
  }

  public static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (
      control.value.match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  public static nonUrlValidator(control) {
    if (control.value === null) {
      return null;
    }
    if (control.value.includes('http') || control.value.includes('https')) {
      return {
        containsUrl: true,
      };
    }

    if (
      control.value.match(
        /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)$/,
      )
    ) {
      return {
        containsUrl: true,
      };
    }

    return null;
  }

  public static emailValidator(control) {
    if (!control.value) {
      return null;
    }

    const lowerCaseControlValue = control.value.toLowerCase();

    if (
      !lowerCaseControlValue.match(
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
      )
    ) {
      return { email: true };
    } else {
      return null;
    }
  }

  public static euurUrlValidator(control) {
    if (!control.value) {
      return null;
    }
    // {6,100}           - Assert password is between 6 and 100 characters
    if (control.value.match(/^(http|https):\/\//)) {
      return { invalidEuurUrl: true };
    } else {
      return null;
    }
  }

  public static passwordValidator(control) {
    if (!control.value) {
      return null;
    }
    // Heavily inspired / copy pasted from: https://stackoverflow.com/a/33589907
    // - .{0,9} at least 10 chars
    // - [^0-9]* at least 1 number
    // - [^A-Z]* at least 1 upper case
    // - [^a-z]* at least 1 lower case
    // - [a-zA-Z0-9]* at least one special character

    if (control.value.match(/^(.{0,9}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/)) {
      return { invalidPassword: true };
    } else {
      return null;
    }
  }

  public static phoneValidator(control) {
    if (!control.value) {
      return null;
    }
    // d                 - For numeric values
    // \-\+ \(\)         - Aallow chars - + ()
    if (control.value.match(/^[\d\.\-\+ \(\)]+$/)) {
      return null;
    } else {
      return { invalidPhone: true };
    }
  }

  public static matchText(text: string, errorMessage: string) {
    return (control: AbstractControl) => {
      if (!control?.value) {
        return null;
      }

      const lowerCaseControlValue = control.value.toLowerCase();
      const compareText = text.toLowerCase();

      if (lowerCaseControlValue !== compareText) {
        return { matchTextInvalid: errorMessage };
      }
      return null;
    };
  }

  public static matchValidator(otherControlName: string, errorMessage: string) {
    let thisControl: UntypedFormControl;
    let otherControl: UntypedFormControl;

    return function matchOtherValidate(control: UntypedFormControl) {
      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as UntypedFormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (otherControl.value !== thisControl.value) {
        return {
          matchFailed: errorMessage,
        };
      }

      return null;
    };
  }

  public static termsAgreement(control: UntypedFormControl): { [key: string]: boolean } {
    if (!control.value) {
      return { termsAgreement: true };
    } else {
      return null;
    }
  }

  public static decimalValidator(control) {
    if (!control.value) {
      return null;
    }

    const num = parseFloat(control.value);
    if (!Number.isNaN(num)) {
      return null;
    }

    return { invalidDecimal: true };
  }

  public static numberWithoutDecimalValidator(control) {
    const value: string = control.value;
    if (!value) {
      return null;
    }

    return /^[0-9]\d*$/.test(value) ? null : { invalidNumber: true };
  }

  public static numberValidator(control) {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    return /^\d+$/.test(value) ? null : { invalidNumber: true };
  }

  public static numberValidatorAllowNegative(control) {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    return /^-?(0|[1-9]\d*)$/.test(value) ? null : { invalidNumber: true };
  }

  public static minItems(minLength = 1) {
    return (control) => {
      if (!control.value) {
        return null;
      }

      if (!control.length) {
        return null;
      }

      if (control.length >= minLength) {
        return null;
      }

      return {
        minItems: {
          minLength: minLength,
        },
      };
    };
  }

  public static timeValidator(control) {
    if (!control.value || control.value === '') {
      return null;
    }

    const check = /^(2[0-3]|[01][0-9]):([0-5][0-9])(:[0-5][0-9])?$/;

    if (control.value.match(check)) {
      return null;
    }

    return { invalidTime: true };
  }

  public static maxHoursNotExceedDay(control) {
    if (!control.value || control.value === '') {
      return null;
    }

    const val = control.value;
    if (val <= 24) {
      return null;
    }

    return { invalidTime: true };
  }

  public static dateValidator(control) {
    if (!control.value || control.value === '') {
      return null;
    }
    if (Array.isArray(control.value)) {
      const startDate = parseDate(control.value[0]);
      const endDate = parseDate(control.value[1]);

      if (isValid(startDate) && isValid(endDate)) {
        return null;
      }
    } else {
      if (isValid(parseDate(control.value))) {
        return null;
      }
    }

    return { invalidDate: true };
  }

  public static dateContractValidator(dateInContract: boolean) {
    return (control): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (dateInContract) {
        return null;
      }

      return {
        dateBeforeContract: true,
      };
    };
  }

  public static minDate(minDate: string | Date | Function) {
    if (!(minDate instanceof Function) && !isValid(parseDate(minDate))) {
      throw Error('maxDate value must be a valid date');
    }

    return (control): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      //invalid date
      if (this.dateValidator(control)) {
        return null;
      }

      const selectedDate = parseDate(control.value);

      const checkDate = minDate instanceof Function ? minDate() : minDate;
      const checkStartOfDay = startOfDay(parseDate(checkDate));

      if (!isValid(checkStartOfDay)) {
        return null;
      }

      if (selectedDate >= checkStartOfDay) {
        return null;
      }

      return {
        minDate: {
          minDate: format(checkStartOfDay, 'P'),
          selectedDate: format(selectedDate, 'P'),
        },
      };
    };
  }

  public static maxDate(maxDate: string | Date) {
    const checkDate = startOfDay(parseDate(maxDate));

    if (!isValid(checkDate)) {
      throw Error('maxDate value must be a valid date');
    }

    return (control): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      //invalid date
      if (this.dateValidator(control)) {
        return null;
      }

      const selectedDate = startOfDay(parseDate(control.value));

      if (selectedDate <= checkDate) {
        return null;
      }

      return {
        maxDate: {
          maxDate: format(checkDate, 'yyyy-MM-dd'),
          selectedDate: format(selectedDate, 'yyyy-MM-dd'),
        },
      };
    };
  }

  public static periodCheck(startDateField, endDateField, allowEqual = true) {
    return (control: UntypedFormGroup) => {
      const startDate = control.value[startDateField];
      const endDate = control.value[endDateField];

      if (!startDate || !endDate) {
        return null;
      }

      const startDateTime = parseDate(startDate);
      const endDateTime = parseDate(endDate);

      if (!isValid(startDateTime) || !isValid(endDateTime)) {
        return null;
      }

      if (allowEqual && startDate <= endDate) {
        return null;
      }

      if (!allowEqual && startDate < endDate) {
        return null;
      }

      return {
        period: {
          minDate: format(startDateTime, 'P'),
          selectedDate: format(endDateTime, 'P'),
        },
      };
    };
  }

  public static rangePickerPeriodValidator(allowEqual = true) {
    return (control: UntypedFormGroup) => {
      if (!control.value) {
        return null;
      }

      const startDate = control.value[0];
      const endDate = control.value[1];

      if (!startDate || !endDate) {
        return null;
      }

      const startDateTime = parseDate(startDate);
      const endDateTime = parseDate(endDate);

      if (!isValid(startDateTime) || !isValid(endDateTime)) {
        return null;
      }

      if (allowEqual && startDate <= endDate) {
        return null;
      }

      if (!allowEqual && startDate < endDate) {
        return null;
      }

      return {
        period: {
          minDate: format(startDateTime, 'P'),
          selectedDate: format(endDateTime, 'P'),
        },
      };
    };
  }

  /**
   * Validate recurrence rules for scheduling a shift or Open shift
   * @param {FormControl} control
   */
  public static recurrenceSelectValidator(control): { [key: string]: any } {
    const value = control.value;

    if (!value || value.length === 0) {
      return {
        recurrence: true,
      };
    }

    return null;
  }

  /**
   * Function to mark fields for validationcheck when current field is touched
   * @param fields
   */
  public static onChangeCheck(...fields: string[]) {
    return (control: AbstractControl): { [key: string]: any } => {
      const form = control.root;

      fields.forEach((field) => {
        const updateControl = form.get(field);
        if (!updateControl || updateControl.disabled) {
          return;
        }

        updateControl.updateValueAndValidity({ onlySelf: true });
      });

      return null;
    };
  }

  public static lengthOfPeriodValidator(period: number) {
    return (control: UntypedFormControl) => {
      if (!control) {
        return null;
      }

      const startDate = parseDate(control.value[0]);
      const endDate = parseDate(control.value[1]);

      if (!isValid(startDate) || !isValid(endDate)) {
        return null;
      }

      const days = Math.abs(differenceInDays(startDate, endDate));

      if (days < period) {
        return null;
      }

      return { moreThanPeriod: { period } };
    };
  }

  public static selectAtLeastOneCheckbox(fields: string[]) {
    return (control: UntypedFormGroup) => {
      if (!control) {
        return null;
      }

      const formFields = fields.map((field) => control.get(field));

      if (formFields.length === 0 || !formFields[0]) {
        return null;
      }

      if (formFields.some((item) => item.value)) {
        return null;
      } else {
        return {
          selectAtLeastOneCheckbox: true,
        };
      }
    };
  }

  public static atLeastOne(validator: ValidatorFn, controls: string[] = null) {
    return (group: FormGroup) => {
      if (!controls) {
        controls = Object.keys(group.controls);
      }
      const hasAtLeastOne = group && group.controls && controls.some((key) => !validator(group.controls[key]));
      return hasAtLeastOne
        ? null
        : {
            atLeastOne: true,
          };
    };
  }

  public static negativeNumberValidator(control) {
    if (!control.value) {
      return null;
    }

    const number = parseFloat(control.value);

    if (number >= 0) {
      return null;
    }

    return {
      negativeNumber: true,
    };
  }

  public static absenceConflict(hasConflict) {
    return (control: UntypedFormGroup) => {
      if (!control.value) {
        return null;
      }

      if (hasConflict) {
        return {
          absenceConflict: true,
        };
      }

      return null;
    };
  }

  public static cannotContainSlashes(control) {
    if (!control.value) {
      return null;
    }

    return control.value.includes('/') ? { containSlashes: true } : null;
  }

  public static hasValueInArray<T>(collections: T[], field: string) {
    return (control: UntypedFormGroup) => {
      if (!control || collections?.length === 0) {
        return null;
      }

      const findInArray = collections.find(
        (object) => object[field] && object[field]?.toLocaleLowerCase() === control.value?.toLocaleLowerCase(),
      );
      return findInArray ? { hasValue: true } : null;
    };
  }

  public static hexColor(control) {
    const color = control.value.toLowerCase();
    if (!color) {
      return null;
    }

    if (color.match(/^#([0-9a-f]{3}){1,2}$/)) {
      return null;
    } else {
      return { invalidHexColor: true };
    }
  }

  public static verificationCodeValidator(control) {
    const check = /^(\s*[0-9]\s*){6}$/;

    return control.value.match(check) ? null : { invalidVerificationCode: true };
  }

  public static employeeContract(hasNoContract): ValidatorFn {
    return (control: FormGroup) => {
      if (!control.value) {
        return null;
      }
      return hasNoContract ? { hasNoContract } : null;
    };
  }
}
